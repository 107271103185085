/* eslint-disable */
// // @ts-nocheck
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import * as echarts from 'echarts'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'
import { useAwsScanCoverageQuery } from 'ui-v2/src/hooks/queries/aws-stats'
import { useOvaAssetsScannedQuery } from 'ui-v2/src/hooks/queries/ova-statistics'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useGetFiltersForListAwsAssetSummariesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import dayjs from 'dayjs'
import {
  setRadarChartSeriesAndGraphicOptions,
  baseRadarChartOptions,
} from './radar-chart-options'
import {
  ListSnapshotsVulnerabilities,
  ListVolumesVulnerabilities,
  RiskLevel,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import { useLiveAssetsDataForAssetsVulnerabilities } from '../../../assets-vulnerabilities/utils'
import { clampPercentage } from 'ui-v2/src/lib/helpers/num.helper'
import { BackupType } from 'ui-v2/src/lib/models/assets/live'
import useRpoStore from 'ui-v2/src/components/drawers/resilience-score/rpo-store'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import TooltipComponent from './tooltip-component'
import ElastioLogoIcon from 'ui-v2/src/assets/icons/elastio-logo-icon'

export enum ResilienceScoreColor {
  RED = 'red',
  YELLOW = 'yellow',
  GREEN = 'green',
}

type RadarChartProps = {
  selectedSection: RadarChartAxes
  resilienceScore: number
  setSelectedSection: (section: RadarChartAxes) => void
  setResilienceScore: Dispatch<SetStateAction<number>>
  setResilienceScoreColor: Dispatch<ResilienceScoreColor>
  openResilienceScoreDrawer: () => void
  resilienceScoreColor: ResilienceScoreColor
}

export default function RadarChart({
  selectedSection,
  resilienceScore,
  setResilienceScore,
  setSelectedSection,
  setResilienceScoreColor,
  openResilienceScoreDrawer,
  resilienceScoreColor,
}: RadarChartProps) {
  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  //Asset Coverage metric
  const { data: awsScanCoverageData, isLoading: isAwsScanCoverageDataLoading } =
    useAwsScanCoverageQuery({})
  const {
    data: ovaAssetsScannedData,
    isLoading: isOvaAssetsScannedDataLoading,
  } = useOvaAssetsScannedQuery({})

  const assetCoveragePercentage = useMemo(() => {
    if (!awsScanCoverageData || !ovaAssetsScannedData) {
      return 0
    }

    const services = ['ebs', 'ec2', 'efs', 's3'] as const

    const awsCoveredCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.covered ?? 0),
      0
    )

    const awsTotalCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.total ?? 0),
      0
    )

    return clampPercentage(
      Math.round((awsCoveredCount / (awsTotalCount || 1)) * 100)
    )
  }, [awsScanCoverageData, ovaAssetsScannedData])

  //Ransomware Safety
  const ransomwareSafetyPercentage = useMemo(() => {
    if (!assetsLiveData || !ovaAssetsScannedData) {
      return 0
    }

    const totalLiveAssets =
      assetsLiveData.ebsVolumes.length +
      assetsLiveData.ec2Instances.length +
      assetsLiveData.efs.length +
      assetsLiveData.genericHosts.length +
      assetsLiveData.s3Buckets.length

    const assetsWithThreatsSet = new Set()
    assetsLiveData.threats.forEach((threat) => {
      if (threat.source.asset?.assetId) {
        assetsWithThreatsSet.add(threat.source.asset?.assetId)
      }
    })

    const totalOvaServers = ovaAssetsScannedData.totalAssets
    const infectedOvaServers = ovaAssetsScannedData.infectedAssets

    const totalAssets = totalLiveAssets + totalOvaServers

    if (
      totalAssets === 0 &&
      assetsWithThreatsSet.size + infectedOvaServers === 0
    ) {
      return 0
    }

    return clampPercentage(
      Math.round(
        ((totalAssets - (assetsWithThreatsSet.size + infectedOvaServers)) /
          totalAssets || 1) * 100
      )
    )
  }, [assetsLiveData, ovaAssetsScannedData])

  //Encryption Safety
  const awsAssetSummaryFilter = useMemo(
    () => ({
      startAt: Math.floor(dayjs().subtract(30, 'day').valueOf() / 1000),
      endAt: Math.floor(dayjs().valueOf() / 1000),
    }),
    []
  )

  const {
    data: getFiltersForListAwsAssetSummariesData,
    isLoading: isGetFiltersForListAwsAssetSummariesDataLoading,
  } = useGetFiltersForListAwsAssetSummariesQuery(awsAssetSummaryFilter)

  const encryptionIntegrityPercentage = useMemo(() => {
    if (!assetsLiveData || !getFiltersForListAwsAssetSummariesData) {
      return 0
    }

    const total =
      assetsLiveData.ebsVolumes.length + assetsLiveData.s3Buckets.length

    const encryptionActivityCount =
      getFiltersForListAwsAssetSummariesData.ebsVolumes.length +
      getFiltersForListAwsAssetSummariesData.s3Buckets.length

    return clampPercentage(
      Math.round(((total - encryptionActivityCount) / (total || 1)) * 100)
    )
  }, [assetsLiveData, getFiltersForListAwsAssetSummariesData])

  //Storage Health

  const {
    data: liveAssetsDataForVulnerabilities,
    isLoading: isLiveAssetsDataForVulnerabilitiesLoading,
  } = useLiveAssetsDataForAssetsVulnerabilities()

  const storageHealthPercentage = useMemo(() => {
    if (!liveAssetsDataForVulnerabilities) {
      return 0
    }

    const { instances, volumes, snapshots, scans, lastBackups } =
      liveAssetsDataForVulnerabilities

    const filters = {
      riskType: [RiskLevel.Low, RiskLevel.Medium, RiskLevel.High],
    }

    const volumeVulnerabilities = new ListVolumesVulnerabilities({
      instances,
      lastBackups,
      scans,
      snapshots,
      volumes,
    }).execute(filters, new VulnerabilitiesDetectionStrategy()).volumesList
      .length

    const snapshotVulnerabilities = new ListSnapshotsVulnerabilities({
      snapshots,
      volumes,
    }).execute(filters, new VulnerabilitiesDetectionStrategy()).length

    const vulnerabilitiesCount = volumeVulnerabilities + snapshotVulnerabilities
    const totalCount = volumes.length + snapshots.length

    if (totalCount === 0 && vulnerabilitiesCount === 0) {
      return 0
    }

    return clampPercentage(
      Math.round(100 - (vulnerabilitiesCount / (totalCount || 1)) * 100)
    )
  }, [liveAssetsDataForVulnerabilities])

  //Recovery Assurance
  const { rpoHours } = useRpoStore()

  const recoveryAssurancePercentage = useMemo(() => {
    if (!assetsLiveData) {
      return 0
    }

    const lastBackupsArray = Array.from(
      assetsLiveData.lastBackups.backups
    ).reduce<Array<BackupType>>((backupsAcc, [, backup]) => {
      return [...backupsAcc, backup]
    }, [])

    const totalBackups = lastBackupsArray.length

    const backupsUnderRpo = lastBackupsArray.filter((backup) => {
      const backupTime = dayjs(backup.timestamp)
      const currentTime = dayjs()
      const diffInSeconds = currentTime.diff(backupTime, 'second')
      return diffInSeconds <= rpoHours * 60 * 60
    }).length

    return clampPercentage(
      Math.round((backupsUnderRpo / (totalBackups || 1)) * 100)
    )
  }, [assetsLiveData, rpoHours])

  const theme = useTheme()

  const radarChartRef = useRef(null)

  const isLoading =
    isAssetsLiveDataLoading ||
    isAwsScanCoverageDataLoading ||
    isOvaAssetsScannedDataLoading ||
    isGetFiltersForListAwsAssetSummariesDataLoading ||
    isLiveAssetsDataForVulnerabilitiesLoading

  useEffect(() => {
    if (!isLoading) {
      const resiliencePercentage = {
        assetCoveragePercentage: assetCoveragePercentage || 0,
        ransomwareSafetyPercentage: ransomwareSafetyPercentage || 0,
        encryptionIntegrityPercentage: encryptionIntegrityPercentage || 0,
        recoveryAssurancePercentage: recoveryAssurancePercentage || 0,
        storageHealthPercentage: storageHealthPercentage || 0,
      }

      const entries = Object.values(resiliencePercentage)
      const score = clampPercentage(
        entries.reduce((accumulator, current) => current + accumulator, 0) /
          (entries.length || 1)
      )
      setResilienceScore(score)

      if (
        ransomwareSafetyPercentage < 100 ||
        encryptionIntegrityPercentage < 100 ||
        recoveryAssurancePercentage < 100
      ) {
        setResilienceScoreColor(ResilienceScoreColor.RED)
      } else if (
        assetCoveragePercentage < 100 ||
        storageHealthPercentage < 100
      ) {
        setResilienceScoreColor(ResilienceScoreColor.YELLOW)
      } else {
        setResilienceScoreColor(ResilienceScoreColor.GREEN)
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (!radarChartRef.current) return
    let radarchart = echarts.getInstanceByDom(radarChartRef.current)

    if (!radarchart) {
      radarchart = echarts.init(radarChartRef.current)
    }

    const option = baseRadarChartOptions(theme)

    radarchart.setOption(option)

    const handleResize = () => {
      radarchart?.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!radarChartRef.current) return
    const radarchart = echarts.getInstanceByDom(radarChartRef.current)

    if (radarchart) {
      const option = setRadarChartSeriesAndGraphicOptions(
        recoveryAssurancePercentage,
        assetCoveragePercentage,
        ransomwareSafetyPercentage,
        encryptionIntegrityPercentage,
        storageHealthPercentage,
        isLoading,
        resilienceScoreColor,
        theme
      )
      radarchart.setOption(option)
    }
  }, [
    assetCoveragePercentage,
    ransomwareSafetyPercentage,
    encryptionIntegrityPercentage,
    recoveryAssurancePercentage,
    storageHealthPercentage,
    selectedSection,
    isLoading,
    theme,
    resilienceScoreColor,
  ])

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 400,
      }}
    >
      <Box
        ref={radarChartRef}
        sx={{
          width: { xs: '100%', xl: 700 },
          height: '100%',
          cursor: 'pointer',
          margin: '0 auto',
        }}
      />

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '41.143px',
            height: '41.143px',
            padding: '9.429px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8.571px',
            borderRadius: '25.714px',
            background:
              resilienceScoreColor === ResilienceScoreColor.GREEN
                ? '#319246'
                : resilienceScoreColor === ResilienceScoreColor.YELLOW
                  ? '#FFAB2D'
                  : 'rgba(255, 22, 84, 0.8)',
          }}
        >
          <ElastioLogoIcon />
        </div>
      </div>

      {/* Asset Coverage */}

      <TooltipComponent
        text={
          'Ensures comprehensive scanning of assets, which is critical for ransomware resilience.'
        }
      >
        <div
          style={{
            position: 'absolute',
            top: '35%',
            left: '76%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedSection(RadarChartAxes.ASSET_COVERAGE)
          }}
        >
          <Typography
            style={{
              cursor: 'pointer',
              color:
                selectedSection === RadarChartAxes.ASSET_COVERAGE
                  ? '#80C5FF'
                  : theme.palette.mode === ThemeMode.DARK
                    ? '#B2B5C1'
                    : '#4E5665',
              lineHeight: '15px',
              letterSpacing: '0.4px',
              fontSize: '15px',
              whiteSpace: 'pre-line',
            }}
          >
            {`${assetCoveragePercentage || 0}% \nAsset Coverage`}
          </Typography>
        </div>
      </TooltipComponent>

      {/*Storage Health*/}

      <TooltipComponent
        text={
          'Assesses secure configurations for storage assets to minimize vulnerabilities.'
        }
      >
        <div
          style={{
            position: 'absolute',
            left: '8%',
            top: '35%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedSection(RadarChartAxes.STORAGE_HEALTH)
          }}
        >
          <Typography
            style={{
              cursor: 'pointer',
              color:
                selectedSection === RadarChartAxes.STORAGE_HEALTH
                  ? '#80C5FF'
                  : theme.palette.mode === ThemeMode.DARK
                    ? '#B2B5C1'
                    : '#4E5665',
              lineHeight: '15px',
              letterSpacing: '0.4px',
              fontSize: '15px',
              textAlign: 'right',
              whiteSpace: 'pre-line',
            }}
          >
            {`${storageHealthPercentage || 0}% \nStorage Health`}
          </Typography>
        </div>
      </TooltipComponent>

      {/*Recovery Assurance*/}
      <TooltipComponent
        text={
          'Verifies the availability and integrity of clean recovery points within set SLAs for reliable restoration.'
        }
      >
        <div
          style={{
            position: 'absolute',
            top: '5%',
            left: '37%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedSection(RadarChartAxes.RECOVERY_ASSURANCE)
          }}
        >
          {recoveryAssurancePercentage < 100 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  color:
                    theme.palette.mode === ThemeMode.DARK ? '#fff' : '#4E5665',
                  fontWeight: 700,
                  paddingLeft: '4px',
                  textAlign: 'right',
                }}
              >
                {recoveryAssurancePercentage}%
              </Typography>
              <StatusChip
                sx={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  color: '#fff',
                  fontWeight: 700,
                  padding: '2px 4px',
                  lineHeight: '16px',
                  letterSpacing: '0.4px',
                  height: '24px',
                }}
                type={'error'}
                label={'Recovery Assurance'}
              />
            </div>
          ) : (
            <Typography
              style={{
                cursor: 'pointer',
                color:
                  selectedSection === RadarChartAxes.RECOVERY_ASSURANCE
                    ? '#80C5FF'
                    : theme.palette.mode === ThemeMode.DARK
                      ? '#B2B5C1'
                      : '#4E5665',
                lineHeight: '15px',
                letterSpacing: '0.4px',
                fontSize: '15px',
                textAlign: 'right',
              }}
            >
              {`100% Recovery Assurance`}
            </Typography>
          )}
        </div>
      </TooltipComponent>
      {/*Encryption Safety*/}
      <TooltipComponent
        text={
          'Monitors encryption activity to detect unauthorized data encryption in backups and storage.'
        }
      >
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '12%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedSection(RadarChartAxes.ENCRYPTION_INTEGRITY)
          }}
        >
          {encryptionIntegrityPercentage < 100 ? (
            <>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  color:
                    theme.palette.mode === ThemeMode.DARK ? '#fff' : '#4E5665',
                  fontWeight: 700,
                  paddingLeft: '4px',
                  textAlign: 'right',
                }}
              >
                {encryptionIntegrityPercentage}%
              </Typography>
              <StatusChip
                sx={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  color: '#fff',
                  fontWeight: 700,
                  padding: '2px 4px',
                  lineHeight: '16px',
                  letterSpacing: '0.4px',
                  height: '24px',
                }}
                type={'error'}
                label={'Encryption Safety'}
              />
            </>
          ) : (
            <Typography
              style={{
                cursor: 'pointer',
                color:
                  selectedSection === RadarChartAxes.ENCRYPTION_INTEGRITY
                    ? '#80C5FF'
                    : theme.palette.mode === ThemeMode.DARK
                      ? '#B2B5C1'
                      : '#4E5665',
                whiteSpace: 'pre-line',
                lineHeight: '15px',
                letterSpacing: '0.4px',
                fontSize: '15px',
                textAlign: 'right',
              }}
            >
              {`100%\nEncryption Safety`}
            </Typography>
          )}
        </div>
      </TooltipComponent>
      {/*Ransomware Safety*/}
      <TooltipComponent
        text={
          "Measures 'cleanliness' against ransomware, detecting threats pre- and post-detonation in backups and storage."
        }
      >
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '68%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedSection(RadarChartAxes.RANSOMWARE_SAFETY)
          }}
        >
          {ransomwareSafetyPercentage < 100 ? (
            <>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  color:
                    theme.palette.mode === ThemeMode.DARK ? '#fff' : '#4E5665',
                  fontWeight: 700,
                  paddingLeft: '4px',
                }}
              >
                {ransomwareSafetyPercentage}%
              </Typography>
              <StatusChip
                sx={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  color: '#fff',
                  fontWeight: 700,
                  padding: '2px 4px',
                  lineHeight: '16px',
                  letterSpacing: '0.4px',
                  height: '24px',
                }}
                type={'error'}
                label={'Ransomware Safety'}
              />
            </>
          ) : (
            <Typography
              style={{
                cursor: 'pointer',
                color:
                  selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
                    ? '#80C5FF'
                    : theme.palette.mode === ThemeMode.DARK
                      ? '#B2B5C1'
                      : '#4E5665',
                whiteSpace: 'pre-line',
                lineHeight: '15px',
                letterSpacing: '0.4px',
                fontSize: '15px',
              }}
            >
              {`100%\nRansomware Safety`}
            </Typography>
          )}
        </div>
      </TooltipComponent>
      <Box
        className="ui-v2-rotating-overlay"
        sx={{
          opacity: theme.palette.mode === 'dark' ? 1 : 0.3,
          '&:after': {
            background:
              theme.palette.mode === 'dark'
                ? 'radial-gradient(rgb(255 0 0 / 40%), rgba(0, 100, 193, 0.007) 70%)'
                : 'rgba(117, 125, 132, 0.2)',
          },
        }}
      ></Box>
      <Box component={'div'} className={`resilience-score`}>
        <Typography
          fontWeight={700}
          fontSize={32}
          color={
            theme.palette.mode === 'dark'
              ? '#fff'
              : resilienceScoreColor === ResilienceScoreColor.GREEN
                ? '#319246'
                : resilienceScoreColor === ResilienceScoreColor.YELLOW
                  ? '#DA8300'
                  : 'rgba(255, 22, 84, 1)'
          }
          mt={'-3px'}
        >
          {Math.round(resilienceScore)}%
        </Typography>

        <Button
          variant="contained"
          sx={{
            textTransform: 'capitalize',
            borderRadius: '50px',
            marginTop: '60px !important',
          }}
          size="small"
          onClick={openResilienceScoreDrawer}
        >
          Define RPO
        </Button>
      </Box>
    </Box>
  )
}
