import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import Close from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import ListItemText from '@mui/material/ListItemText'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { useLinkAssetAccounts } from 'ui-v2/src/hooks/queries/red-stack/list-asset-accounts'
import { useToast } from 'ui-v2/src/hooks/toast'

const BindLinkedProtectedAccount = () => {
  const [inputValue, setInputValue] = useState('')
  const [accountNumbers, setAccountNumbers] = useState<Array<string>>([])
  const [error, setError] = useState<string>('')
  const { id } = useParams()
  const toast = useToast()

  const { action } = useParams()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.BIND_LINKED_PROTECTED_ACCOUNT
  const navigate = useNavigate()

  const { mutateAsync: linkAssetAccounts, isPending } = useLinkAssetAccounts()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleAddAccounts = () => {
    const newAccounts = inputValue
      .split(',')
      .map((num) => num.trim())
      .filter((num) => num !== '')

    if (newAccounts.some((num) => !/^[0-9]{12}$/.test(num))) {
      setError(
        'Each account number must be exactly 12 digits long and contains only numbers.'
      )
      return
    }

    setError('')
    const uniqueAccounts = newAccounts.filter(
      (num) => !accountNumbers.includes(num)
    )
    if (uniqueAccounts.length > 0) {
      setAccountNumbers((prev) => [...prev, ...uniqueAccounts])
    }
    setInputValue('')
  }

  const handleDeleteAccount = (account: string) => {
    setAccountNumbers((prev) => prev.filter((num) => num !== account))
  }

  const handleCloseDrawer = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
    setInputValue('')
    setAccountNumbers([])
  }

  const handleBindClick = async () => {
    if (!id || accountNumbers.length === 0) {
      return
    }

    try {
      const linkAccountsResult = await Promise.all(
        accountNumbers.map((accountNumber) => {
          return linkAssetAccounts({
            connectorAccountId: id,
            assetAccountId: accountNumber,
          })
        })
      )

      if (linkAccountsResult?.length > 0) {
        toast.success('Linking in progress')
        handleCloseDrawer()
      }
    } catch (_) {
      toast.success('Linking in progress')
      handleCloseDrawer()
    }
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === ThemeMode.DARK ? '#1A1F28' : '',
        '& .MuiPaper-root': {
          backgroundColor:
            theme.palette.mode === ThemeMode.DARK ? '#1A1F28' : '',
          width: '641px',
          padding: '24px',
        },
      })}
    >
      <DrawerContent>
        <List sx={{ padding: 0 }}>
          <ListItem
            sx={{
              paddingRight: '70px',
              paddingTop: 0,
              paddingLeft: 0,
              right: 0,
            }}
            secondaryAction={[
              <Button
                variant="contained"
                type="submit"
                onClick={handleBindClick}
                disabled={!accountNumbers.length || isPending}
                sx={{
                  height: '32px',
                  width: '45px',
                  marginRight: '8px',
                }}
              >
                Link
              </Button>,
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  handleCloseDrawer()
                }}
              >
                <Close />
              </IconButton>,
            ]}
          >
            <Typography
              fontWeight={700}
              fontSize={22}
              sx={(theme) => ({
                color:
                  theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#50565c',
              })}
            >
              Link Protected Accounts
            </Typography>
          </ListItem>
        </List>
        <Typography
          sx={(theme) => ({
            color:
              theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#50565c',
            mt: 2,
            fontSize: '16px',
            padding: '0',
            lintHeight: '180%',
          })}
        >
          Use this option to manually bind already installed protected accounts
          if the SNS topic is disabled during the CloudFormation or Terraform
          deployment. After the stack is deployed, add the account numbers here
          to link them to the console.
        </Typography>
        <Box
          sx={{
            width: '100%',
            color: 'white',
            borderRadius: 2,
            padding: '0',
          }}
        >
          <Typography
            sx={(theme) => ({
              color:
                theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#50565c',
              paddingTop: '8px',
              mb: '8px',
            })}
          >
            <strong>Add Account Numbers</strong> (Comma Separated)
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <TextField
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Account Number"
              error={!!error}
              helperText={error}
              sx={(theme) => ({
                color:
                  theme.palette.mode === ThemeMode.DARK ? '#B2B5C1' : '#4e5665',
                width: '330px',
                height: '32px',
                marginRight: '16px',
                '& .MuiOutlinedInput-input': {
                  padding: '4px 16px',
                },
                backgroundColor:
                  theme.palette.mode === ThemeMode.DARK ? '#181B1F' : '#F6F9FC',
              })}
            />
            <Button
              variant="contained"
              onClick={handleAddAccounts}
              sx={{
                color: 'white',
                ':hover': { backgroundColor: 'rgba(0, 118, 172, 0.9)' },
                width: '58px',
                height: '32px',
              }}
            >
              Add
            </Button>
          </Box>
          {accountNumbers.length > 0 && (
            <>
              <Typography mb={'8px'} mt={'16px'} variant="subtitle1">
                Accounts
              </Typography>
              <List
                sx={(theme) => ({
                  backgroundColor:
                    theme.palette.mode === ThemeMode.DARK
                      ? '#181B1F'
                      : '#F6F9FC',
                  borderRadius: 1,
                  width: '190px',
                  padding: 0,
                })}
              >
                {accountNumbers.map((account, index) => (
                  <ListItem
                    sx={{
                      paddingRight: '16px',
                      borderBottom:
                        index !== accountNumbers.length - 1
                          ? '#4E5665 1px solid'
                          : '',
                    }}
                    key={account}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => handleDeleteAccount(account)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={account}
                      sx={(theme) => ({
                        color:
                          theme.palette.mode === ThemeMode.DARK
                            ? '#B2B5C1'
                            : '#4e5665',
                      })}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default BindLinkedProtectedAccount
